import { RouteRecordRaw } from 'vue-router';

const reportRouter: Array<RouteRecordRaw> = [
   {
      path: 'reports',
      name: 'w-reports',
      children: [
         {
            path: 'overall-detailed-reports',
            name: 'w-overall-detailed-reports',
            meta: {
               title: 'Sales Transaction',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/overall-detailed-reports/base.vue'
               ),
         },
         {
            path: 'clients-transaction-report',
            name: 'w-clients-transaction-report',
            meta: {
               title: 'Client/Products Transaction',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/client-transactions-reports/base.vue'
               ),
         },
         {
            path: 'overall-model-report',
            name: 'w-overall-model-report',
            meta: {
               title: 'Models',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/overall-model-reports/base.vue'
               ),
         },
         {
            path: 'product-sales-report',
            name: 'w-product-sales-report',
            meta: {
               title: 'Product Sales',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/product-sales-report/base.vue'
               ),
         },
         {
            path: 'best-selling-customers-report',
            name: 'w-best-selling-customers-report',
            meta: {
               title: 'Best Selling Customers',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/best-selling-customers-report/base.vue'
               ),
         },
         {
            path: 'best-selling-products-report',
            name: 'w-best-selling-products-report',
            meta: {
               title: 'Best Selling Products',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/best-selling-product-report/base.vue'
               ),
         },
         {
            path: 'sales-business-transaction-report',
            name: 'w-sales-business-transaction-report',
            meta: {
               title: 'Sales with tax',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/sales-business-movement-report/base.vue'
               ),
         },
         {
            path: 'customers-info',
            name: 'w-sales-customers-info-report',
            meta: {
               title: 'Customers info',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/customers-info-report/base.vue'
               ),
         },
         {
            path: 'client-account-statement-report',
            name: 'w-client-account-statement-report',
            meta: {
               title: 'Clients Account Statement',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/client-account-statement-report/base.vue'
               ),
         },
        {
          path: 'customer-debt-aging-report',
          name: 'w-customer-debt-aging-report',
          meta: {
            title: 'Customer Debt Aging',
            permission:"warehouse_reports",
          },
          component: () =>
             import(
                '@/views/modules/invoicing/views/settings/reports/customer-debt-aging-report/base.vue'
                ),
        },
         {
            path: 'clients-groups-details-transactions-report',
            name: 'w-clients-groups-details-transactions-report',
            meta: {
               title: 'Clients Groups Details Transactions',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/clients-groups-details-transactions-report/base.vue'
               ),
         },
         {
            path: 'customer-trial-balance-report',
            name: 'w-customer-trial-balance-report',
            meta: {
               title: 'Customer Trial Balance',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/customer-trial-balance-report/base.vue'
               ),
         },
         {
            path: 'salesman-sales-transactions-report',
            name: 'w-salesman-sales-transactions-report',
            meta: {
               title: 'Sales of representatives',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/salesman-sales-transactions-reports/base.vue'
               ),
         },
         {
            path: 'generate-salesman-results',
            name: 'w-generate-salesman-results',
            meta: {
               title: 'representatives collections summary',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/generate-salesman-results/base.vue'
               ),
         },
         {
            path: 'branch-sales-report',
            name: 'w-branch-sales-report',
            meta: {
               title: 'Branch Sales Transaction',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/branch-sales-transaction/base.vue'
               ),
         },
         {
            path: 'sales-performance-evaluation-report',
            name: 'w-sales-performance-evaluation-report',
            meta: {
               title: 'Sales Performance Evaluation',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/sales-performance-evaluation-reports/base.vue'
               ),
         },
         {
            path: 'salesman-commissions',
            name: 'w-salesman-commissions',
            meta: {
               title: 'Representatives Commissions',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/salesman-commissions/base.vue'
               ),
         },
         {
            path: 'invoices-profit',
            name: 'w-invoices-profit',
            meta: {
               title: 'invoices profit',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/invoicing/views/settings/reports/invoices-profit/base.vue'
               ),
         },

         //Purchase
         {
            path: 'purchase-transaction-reports',
            name: 'purchase-transaction-reports',
            meta: {
               title: 'Purchase Transaction',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/purchase/purchase-movement-reports/Index.vue'
               ),
         },
         {
            path: 'product-purchase-report',
            name: 'w-product-purchase-report',
            meta: {
               title: 'Product Purchase',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/purchase/product-purchase-report/Index.vue'
               ),
         },
         {
            path: 'purchase-business-transaction-report',
            name: 'w-purchase-business-transaction-report',
            meta: {
               title: 'Purchases Transaction with tax',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/purchase/purchase-business-movement-report/Index.vue'
               ),
         },
         {
            path: 'salesman-purchase-transactions-report',
            name: 'w-salesman-purchase-transactions-report',
            meta: {
               title: 'Salesman Purchase Transactions',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/purchase/salesman-purchase-transactions-report/Index.vue'
               ),
         },
         {
            path: 'vendors-transaction-report',
            name: 'w-vendors-transaction-report',
            meta: {
               title: 'Vendors Transaction',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/purchase/vendors-transaction-report/Index.vue'
               ),
         },
         {
            path: 'vendor-account-statement-report',
            name: 'w-vendor-account-statement-report',
            meta: {
               title: 'Vendor Account Statement',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/purchase/vendor-account-statement-report/Index.vue'
               ),
         },
         {
            path: 'vendors-groups-details-transactions-report',
            name: 'w-vendors-groups-details-transactions-report',
            meta: {
               title: 'Vendors Groups Details Transactions',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/purchase/vendors-groups-details-transactions-report/Index.vue'
               ),
         },
         //stocktaking
         {
            path: 'stocktaking-details-report',
            name: 'stocktaking-details-report',
            meta: {
               title: 'Adjustment',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/stocktaking/stocktaking-report/Index.vue'
               ),
         },
         {
            path: 'addition-settlement-report',
            name: 'addition-settlement-report',
            meta: {
               title: 'Receipt Settlement',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/stocktaking/addition-settlement-report/Index.vue'
               ),
         },
         {
            path: 'expense-settlement-report',
            name: 'expense-settlement-report',
            meta: {
               title: 'expense settlement report',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/stocktaking/expense-settlement-report/Index.vue'
               ),
         },
         {
            path: 'product-data-reports',
            name: 'w-product-data-reports',
            meta: {
               title: 'Product Data',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/warehouse/product-data-report/Index.vue'
               ),
         },
         {
            path: 'product-stock-reports',
            name: 'w-product-stock-reports',
            meta: {
               title: 'Product Stock',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/warehouse/product-stock-report/Index.vue'
               ),
         },
         {
            path: 'product-profits-reports',
            name: 'w-product-profits-reports',
            meta: {
               title: 'Products Balances',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/warehouse/product-profits-report/Index.vue'
               ),
         },
         {
            path: 'product-transaction-reports',
            name: 'w-product-transaction-reports',
            meta: {
               title: 'Product Transaction',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/warehouse/product-transaction-report/Index.vue'
               ),
         },
         {
            path: 'tax-declaration-report',
            name: 'w-tax-declaration-report',
            meta: {
               title: 'Tax Declaration',
               permission: 'warehouse_reports',
            },
            component: () =>
               import(
                  '@/views/modules/warehouse/views/report/common/tax-declaration-report/Index.vue'
               ),
         },
      ],
   },
];

export default reportRouter;
