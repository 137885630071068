const InvoiceMenuConfig = [
   {
      heading: 'settings',
      route: '/settings',
      module_id: 1,
      pages: [
         {
            heading: 'Invoice settings',
            route: '/invoicing-management/setting',
            permission: 'invoice_setting',
         },
         {
            heading: 'Unit Of Measure',
            route: '/invoicing-management/unit',
            permission: 'units',
         },
         {
            heading: 'Taxes',
            route: '/invoicing-management/taxes',
            permission: 'taxes',
         },
         {
            heading: 'product categories',
            route: '/invoicing-management/product-categories',
            permission: 'product_categories',
         },
         {
            heading: 'products',
            route: '/invoicing-management/products',
            permission: 'products',
         },
         {
            heading: 'Commission Policy',
            route: '/invoicing-management/commission-policy',
            permission: 'commission_policies',
         },
         {
            heading: 'salesmans',
            route: '/invoicing-management/salesman',
            permission: 'salesmen',
         },
         {
            heading: 'Clients Groups',
            route: '/invoicing-management/client-group',
            permission: 'client_groups',
         },
         {
            heading: 'Clients',
            route: '/invoicing-management/clients',
            permission: 'clients',
         },
         {
            heading: 'Workflow Forms',
            module_id: 1,
            route: '/invoicing-management/model-settings',
            permission: 'invoice_models',
         },
         {
            heading: 'Import Result',
            route: '/invoicing-management/import-result',
            permission: 'log_exports',
         },
         {
            heading: 'Import Log',
            route: '/invoicing-management/import-log',
            permission: 'log_exports',
         },
      ],
   },
   {
      heading: 'Daily Transaction', //Sales Models
      route: '/daily-movements',
      module_id: 1,
      name: 'daily_movements',
      pages: [],
   },
   {
      heading: 'Reports', //Sales Models
      route: '/reports',
      pages: [
         {
            heading: 'Tax Declaration',
            route: '/invoicing-management/reports/tax-declaration-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Model',
            route: '/invoicing-management/reports/overall-model-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Sales Transaction',
            route: '/invoicing-management/reports/overall-detailed-reports',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Product Sales',
            route: '/invoicing-management/reports/product-sales-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Best Selling Products',
            route: '/invoicing-management/reports/best-selling-products-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Sales with tax',
            route: '/invoicing-management/reports/sales-business-transactions-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'representatives collections summary',
            route: '/invoicing-management/reports/generate-salesman-results',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Sales of representatives',
            route: '/invoicing-management/reports/salesman-sales-transactions-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Sales Performance Evaluation',
            route: '/invoicing-management/reports/sales-performance-evaluation-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Clients Account Statement',
            route: '/invoicing-management/reports/client-account-statement-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Customer Debt Aging',
            route: '/invoicing-management/reports/customer-debt-aging-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Customers info',
            route: '/invoicing-management/reports/customers-info',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Best Selling Customers',
            route: '/invoicing-management/reports/best-selling-customers-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Clients Groups Sales Transactions',
            route: '/invoicing-management/reports/clients-groups-details-transactions-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Customer Trial Balance',
            route: '/invoicing-management/reports/customer-trial-balance-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Client/Products Transaction',
            route: '/invoicing-management/reports/clients-transaction-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Branch Sales Transaction',
            route: '/invoicing-management/reports/branch-sales-report',
            permission: 'invoicing_reports',
         },
         {
            heading: 'Representatives Commissions',
            route: '/invoicing-management/reports/salesman-commissions',
            permission: 'invoicing_reports',
         },
      ],
   },
];

export default InvoiceMenuConfig;
